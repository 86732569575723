<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import { mapGetters, mapActions } from 'vuex';
import simplebar from "simplebar-vue";

import Swal from "sweetalert2";

export default {
  components: {
    Layout,
    PageHeader,
    simplebar
  },
  data() {
    
    return {
      errors:[],
      title: '',
      item: {
        name: '',
        email: '',
        phoneNumber: '',
        description: '',
        userRole: 'Generic',
        userType: 'Account',
        accountId: null,
        password: '',
        lockoutEnd: null,
        defaultCurrency: 'USD',
        defaultBenchmark: 'SP500',
        managedAccounts: [],
        canUsePredefinedProfilesOnly: false
      },
      breadcrumbs: [
        {
          text: 'Users',
          href: 'users',
          to: { name: 'users' }
        }
      ]
    };
  },
  props:{
    id: {
      type: String,
      required: true,
    },
  },
  created() {
    this.title = this.isNew ? 'New user' : 'Edit user';
    this.init();
  },
  watch: {
   
  },
  computed: {
    allowSaveOrDelete() {
      if(!this.isNew){
        return this.userRole.find(x => x === 'administrator') || (this.userType === 'C8' && this.managedAccounts.find(x => x.id === this.item.accountId));
      } else {
        return this.userRole.find(x => x === 'administrator') || (this.userType === 'C8' && this.managedAccounts.length > 0);
      }
    },
     isBusy (){
      return this.loadingAccounts || this.loadingUsers || this.processing;
    },
    ...mapGetters('accounts', {
        loadingAccounts: 'loading',
        accounts: 'items'
    }),
    ...mapGetters('auth', {
        permissios: 'userPermissions',
        userType: 'userType',
        userRole: 'userRole',
        managedAccounts: 'managedAccounts'
    }),
    isNew(){
      return  this.id.toLowerCase().trim() === 'new';
    },
    ...mapGetters('auth', {
        permissios: 'userPermissions'
    }),
    ...mapGetters('users', {
        loadingUsers: 'loading',
        processing: 'processing'
    })
  },
  methods: {
    ...mapActions('accounts', {
        loadAccounts: 'load'
    }),
     ...mapActions('users', {
        loadById: 'loadById',
        delete: 'delete',
        create: 'create',
        update: 'update'
    }),
    addManagedAccount(account) {
      if(this.item.managedAccounts.find(x => x.id == account.id))
        return;
      
      this.item.managedAccounts = [...this.item.managedAccounts, account]
    },
    removeManagedAccount(account) {
      this.item.managedAccounts = this.item.managedAccounts.filter(x => x.id != account.id);
    },
    async init(){
      await this.loadAccounts();
      if(!this.isNew) {
        this.loadById(this.id).then((response) => {
          let o = JSON.parse(JSON.stringify(response));
          o.accountId = (o.account || {}).id || null;
          o.account = null;
          o.defaultCurrency = o.defaultCurrency || 'USD';
          o.defaultBenchmark = o.defaultBenchmark || 'SP500';
          o.canUsePredefinedProfilesOnly = o.canUsePredefinedProfilesOnly === undefined ? false : o.canUsePredefinedProfilesOnly;
          this.item = o;
        }).catch((err)=>{
          if(err.response.status === 404) {
            this.$router.push({name: 'users'});
          }
        });
      } 
    },
    save(){
      let q;
      const dto = {
        ...this.item,
        managedAccounts: null,
        managedAccountIDs: this.item.managedAccounts.map(x => x.id)
      };
      if(this.isNew) {
        q = this.create(dto);
      } else {
        q = this.update( {id: this.id, input: dto } );
      }
      q.then(() => {
        this.$router.push({name: 'users'});
      }).catch(errors => {
        this.errors = errors;
      });
    },
    confirmDelete() {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(result => {
        if (result.value) {
         this.handleDelete();
        }
      });
    },
    handleDelete(){
     this.delete(this.id).then(() => {
        Swal.fire(this.$t("common.deleted"), this.$t("common.recWasDeleted"), "success");
        this.$router.push({name: 'users'});
      })
      .catch(errors => {
        this.errors = errors;
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" :is-busy="isBusy"/>
    
    <div class="card p-0 cardc8">
      <div class="card-content m-3">

        <div class="alert mt-3 mb-4 alert-dismissible alert-danger" v-if="errors.length > 0">
          <button type="button" aria-label="Close" class="close" @click="errors=[]">×</button>
          <ul>
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </div>

        <div>
          <div class="row">
            <div class="col-xl-6 col-lg-7">
              <div class="form-group">
              <div class="row">
                <div class="col-md-4 col-lg-3 col-xl-3 ">Name <small class="text-muted">[required]</small></div>
                <div class="col-md-8 col-lg-9 col-xl-9">
                  <input type="text" v-model="item.name" class="form-control" autocomplete="nope"/>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-md-4 col-lg-3 col-xl-3 ">Email <small class="text-muted">[required]</small></div>
                <div class="col-md-8 col-lg-9 col-xl-9">
                  <input type="email" v-model="item.email" class="form-control" autocomplete="nope"/>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-md-4 col-lg-3 col-xl-3 ">Phone</div>
                <div class="col-md-8 col-lg-9 col-xl-9">
                  <input type="text" v-model="item.phoneNumber" class="form-control" autocomplete="nope" autofill="nope" />
                </div>
              </div>
            </div>

            <div class="form-group" v-if="userType === 'C8'">
              <div class="row">
                <div class="col-md-4 col-lg-3 col-xl-3 ">User Type</div>
                <div class="col-md-8 col-lg-9 col-xl-9">
                  <b-form-radio style="display:inline-block" class="mr-4" v-model="item.userType" name="userType" value="C8" :disabled="!isNew">C8</b-form-radio>
                  <b-form-radio style="display:inline-block" v-model="item.userType" name="userType" value="Account" :disabled="!isNew">Account</b-form-radio>
                </div>
              </div>
            </div>

            <div class="form-group" v-if="userType === 'C8' && item.userType === 'Account'">
              <div class="row">
                <div class="col-md-4 col-lg-3 col-xl-3 ">Account <small class="text-muted">[required]</small></div>
                <div class="col-md-8 col-lg-9 col-xl-9">
                  <select class="form-control" v-model='item.accountId'>
                    <option :value="null">---</option>
                    <option v-for="a in accounts" :key="a.id" :value="a.id">{{a.name}}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-group" v-if="item.userType === 'Account'">
              <div class="row">
                <div class="col-md-4 col-lg-3 col-xl-3 ">Default Currency <small class="text-muted">[required]</small></div>
                <div class="col-md-8 col-lg-9 col-xl-9">
                  <select class="form-control" v-model="item.defaultCurrency">
                  <option value="USD" selected>
                    United States Dollar (USD)
                  </option>
                  <option value="EUR">
                    Euro (EUR)
                  </option>
                  <option value="GBP">
                    Pound Sterling (GBP)
                  </option>
                  <option value="JPY">
                    Japanese Yen (JPY)
                  </option>
                  <option value="AUD">
                    Australian Dollar (AUD)
                  </option>
                  <option value="NZD">
                    New Zealand Dollar (NZD)
                  </option>
                  <option value="CAD">
                    Canadian Dollar (CAD)
                  </option>
                  <option value="CHF">
                    Swiss Franc (CHF)
                  </option>
                  <option value="NOK">
                    Norwegian Krone (NOK)
                  </option>
                  <option value="SEK">
                    Swedish Krona (SEK)
                  </option>
                </select>
                </div>
              </div>
            </div>

            <!-- <div class="form-group" v-if="item.userType === 'Account'">
              <div class="row">
                <div class="col-md-4 col-lg-3 col-xl-3 ">Default Benchmark <small class="text-muted">[required]</small></div>
                <div class="col-md-8 col-lg-9 col-xl-9">
                  <select class="form-control" v-model="item.defaultBenchmark">
                    <option value="SP500" selected>
                      S&P 500
                    </option>
                  </select>
                </div>
              </div>
            </div> -->

           

            <div class="form-group">
              <div class="row">
                <div class="col-md-4 col-lg-3 col-xl-3 ">User Role</div>
                <div class="col-md-8 col-lg-9 col-xl-9">
                  <b-form-radio style="display:inline-block" class="mr-4" v-model="item.userRole" name="userRole" value="Generic">User</b-form-radio>
                  <b-form-radio style="display:inline-block" v-model="item.userRole" name="userRole" value="Admin">Admin</b-form-radio>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-4 col-lg-3 col-xl-3 ">Can use predefined profiles only</div>
                <div class="col-md-8 col-lg-9 col-xl-9">
                  <b-form-radio style="display:inline-block" class="mr-4" v-model="item.canUsePredefinedProfilesOnly" name="canUsePredefinedProfilesOnly" :value="true">Yes</b-form-radio>
                  <b-form-radio style="display:inline-block" v-model="item.canUsePredefinedProfilesOnly" name="canUsePredefinedProfilesOnly" :value="false">No</b-form-radio>
                </div>
              </div>
            </div>
            <div class="form-group" >
              <div class="row">
                <div class="col-md-4 col-lg-3 col-xl-3 ">Lockout End</div>
                <div class="col-md-8 col-lg-9 col-xl-9">
                  <b-form-datepicker :value="item.lockoutEnd"
                    locale="en" 
                    today-button
                    @input="(v) => item.lockoutEnd = v || null"
                    reset-button
                    close-button
                    dropup
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">

                  </b-form-datepicker>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-md-4 col-lg-3 col-xl-3 ">Password</div>
                <div class="col-md-8 col-lg-9 col-xl-9">
                  <input type="password" v-model="item.password" class="form-control" autocomplete="new-password"/>
                </div>
              </div>
            </div>
            </div>
            <div class="col-xl-6 col-lg-5">
              <div class="form-group">
                <textarea class="form-control" v-model="item.description" rows="17" placeholder="Description...">

                </textarea>
              </div>
            </div>
          </div>
          <div class="mt-2 mb-4"  v-if="userType === 'C8' && item.userType === 'C8'">
            <div class="row">
              <div class="col">
                <h6 class="mt-2" >
                  Available Accounts<br/>
                  <small class="text-muted">Click to add new managed account.</small>
                </h6>
                <simplebar style="max-height: 300px">
                  <div class="list-group">
                    <a href="javascript:void(0)" 
                      class="list-group-item" 
                      v-for="account in accounts.filter(x => !item.managedAccounts.find(a => a.id === x.id))" 
                      :key="account.id"
                      @click="addManagedAccount(account)"
                    >
                      <i class="fa fa-plus mr-4"/> {{account.name}}
                    </a>
                  </div>
                  <span v-if="accounts.filter(x => !item.managedAccounts.find(a => a.id === x.id)).length === 0" class="text-muted">No managed accounts found.</span>
                </simplebar>
              </div>
              <div class="col">
                <h6 class="mt-2">Managed Accounts
                  <br/>
                  <small class="text-muted">Click to remove managed account.</small>
                </h6>
                <simplebar style="max-height: 300px">
                  <div class="list-group">
                    <a href="javascript:void(0)" 
                      class="list-group-item" 
                      v-for="account in item.managedAccounts" 
                      :key="account.id"
                      @click="removeManagedAccount(account)"
                    >
                      <i class="fa fa-minus mr-4"/>  {{account.name}}
                    </a>
                  </div>
                  <span v-if="item.managedAccounts.length === 0" class="text-muted">No managed accounts found.</span>
                </simplebar>
              </div>
            </div>
            
          </div>
          
          <div class="form-group" v-if="allowSaveOrDelete">
            <div class="row">
              <div class="col-md-12">
                <button class="btn btn-outline-danger" v-if="!isNew" @click="confirmDelete">
                   <i class="fa fa-trash mr-2"/> Delete
                </button>

                <button class="btn btn-primary float-right"  @click="save" :disabled="processing || isBusy">
                    <i class="fa fa-save mr-2" v-if="!processing"/>  
                    <i class="fa fa-spin fa-spinner mr-2" v-if="processing"/>  
                    Save
                </button>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </Layout>
</template>